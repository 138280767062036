/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */

import React, { useEffect, useState } from "react";
import {
  getPaymentMethodByMenu,
  createOrder,
  getDeliveryCharge,
  getOrderTables,
  updateOrderTable,
} from "../../components/helpers/Api";
import { getSecoes } from "../../services/SecaoService";
import { Load } from "../../components/widgets/spinner/Spinner";
import { toast } from "react-toastify";
import ObservationModal from "./observation-modal/ObservationModal";
import ConsumerModal from "../../components/partials/modals/consumer-modal/ConsumerModal";
import SelectAdditionalModal from "../../components/partials/modals/select-additional-modal/SelectAdditionalModal";
import PaymentMethodModal from "../../components/partials/modals/payment-method-modal/PaymentMethodModal";

const CriarPedidos = ({ ...props }) => {
  const [data, setData] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [amountProduct, setAmountProduct] = useState(1);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [activeCategory, setActiveCategory] = useState("");
  const [activeProduct, setActivateProduct] = useState("");
  const [cart, setCart] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [delivery, setDelivery] = useState(false);
  const [loading, setLoading] = useState(false);
  const [table, setTable] = useState([]);
  const [persons, setPersons] = useState(1);
  const [percentage, setPercentage] = useState(0);
  const [codTable, setCodTable] = useState("");
  const [error, setError] = useState("");
  const [openModalObservation, setOpenModalObservation] = useState(false);
  const [observation, setObservation] = useState("");
  const [address, setAddress] = useState("");
  const [codOrderTable, setCodOrderTable] = useState("");
  const [deletedItems, setDeletedItems] = useState([]);
  const [serviceValue, setServiceValue] = useState(0);
  const [oldTable, setOldTable] = useState("");
  const [selectedConsumer, setSelectedConsumer] = useState({
    nome: "Selecione um Cliente",
    telefone: "Telefone do usuário",
  });
  const [openConsumerModal, setOpenConsumerModal] = useState(false);
  const [openAdditionalModal, setOpenAdditionalModal] = useState(false);
  const [idProduct, setIdProduct] = useState("");
  const [addiotionals, setAddiotionals] = useState([]);
  const [tamnhos, setTamanhos] = useState([]);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedSabores, setselectedSabores] = useState(null);
  const [openPaymentMethodModal, setOpenPaymentMethodModal] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [indexPayment, setIndexPayment] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({
    descricao: "Selecione uma forma de pagamento",
  });

  function handleAddCount() {
    setAmountProduct(amountProduct + 1);

    let newValue = 0;
    if (selectedSize) {
      newValue += (amountProduct + 1) * selectedSize.valor;
    } else {
      newValue += (amountProduct + 1) * selectedProduct.preco_venda_vista;
    }
    setSubTotal(newValue);
  }

  function handleSubCount() {
    if (amountProduct > 1) {
      setAmountProduct(amountProduct - 1);

      let newValue = 0;
      if (selectedSize) {
        newValue += (amountProduct - 1) * selectedSize.valor;
      } else {
        newValue += (amountProduct - 1) * selectedProduct.preco_venda_vista;
      }
      setSubTotal(newValue);
    }
  }

  function handleAddItem() {
    if (observation.length > 100) {
      return toast.warning(
        "A observação não pode ultrapassar 100 caracteres!!"
      );
    }

    let valorUnitario = selectedSize
      ? selectedSize.valor
      : selectedProduct.preco_venda_vista;

    let item = {
      quantidade: amountProduct,
      descricao: selectedProduct.descricao + "(" + (selectedSabores ? `${selectedSabores}` : "") + ")" + (selectedSize ? ' - ' + selectedSize.descricao : ''),
      tipo: selectedProduct.tipo,
      valor_unitario: valorUnitario,
      valor_total: subTotal,
      cod_pk_produto: selectedProduct.cod_produto,
      observation: observation,
      addiotionals: addiotionals,
      tamanho: selectedSize, // Inclui o tamanho selecionado
    };
    setCart([...cart, item]);
    setAmountProduct(1);
    setSelectedProduct(null);
    setActivateProduct("");
    setObservation("");
    setSelectedSize(null);
    setAddiotionals([]);
    setTamanhos([])
    setSubTotal(0);
  }

  function handleDeleteItemCart(index, id) {
    setTotalProducts(totalProducts - cart[index].valor_total);
    cart.splice(index, 1);
    setCart([...cart]);
    setDeletedItems([...deletedItems, id]);
  }

  useEffect(() => {
    let newTotal = 0;
    cart.forEach((item) => (newTotal += item.valor_total));
    setTotalProducts(newTotal.toFixed(2));
  }, [cart]);

  function handleGetTables() {
    getOrderTables("livre")
      .then((res) => {
        setTable(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleCreateOrder() {
    if (!codTable && !props.data) {
      setError("table-error");
      return toast.warning("Selecione uma mesa !!");
    } else if (cart.length < 1) {
      setError("product-error");

      return toast.warning("Selecione ao menos 1 produto!!");
    } else if (persons.length < 1) {
      setError("persons-error");
      return toast.warning("Informe o número de pessoas!!");
    }
    if (selectedConsumer.nome === "Selecione um Cliente") {
      return toast.warning("Selecione um cliente");
    }

    let data = {
      cod_org: localStorage.getItem("cod_org"),
      cod_pedidomesa: props?.data?.cod_pedidomesa,
      cod_mesa: codTable,
      valor_produtos: totalProducts,
      valor_servico: delivery ? serviceValue : 0,
      percentual: percentage,
      numeropessoas: persons,
      nome_cliente: selectedConsumer.nome,
      telefone_cliente: selectedConsumer.telefone,
      cod_pk_cliente: selectedConsumer.cod_usuario_consumidor,
      forma_pagamento: selectedPaymentMethod,
      address: address,
      old_table: oldTable,
      deletedItems: deletedItems,
      itens: JSON.stringify(cart),
    };
    setLoading(true);
    if (codOrderTable) {
      updateOrderTable(codOrderTable, data)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Pedido atualizado com sucesso !!!");
            props.backView();
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      createOrder(data)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Pedido criado com sucesso !!!");
            props.backView();
          }
        })
        .catch((error) => {
          toast.error(error.response.data.msg);
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  function handleGetDeliveryCharge() {
    getDeliveryCharge()
      .then((res) => {
        setServiceValue(res?.data?.data?.preco_venda_vista);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => { });
  }

  useEffect(() => {
    handleGetSections();
    handleGetTables();
    handleGetDeliveryCharge();
    handleGetPaymentsMethods()
  }, []);

  function handleGetSections() {
    setLoading(true);
    getSecoes("", 1, null, "Ativo", "N")
      .then((res) => {
        setData(res.rows);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (props.data) {
      setCodOrderTable(props?.data?.cod_pedidomesa);
      setCart(props?.data?.tb_ipedidomesas);
      setPersons(props?.data?.numeropessoas);
      setPercentage(props?.data?.percentual);
      setOldTable(props?.data?.cod_mesa);
      setCodTable(props?.data?.cod_mesa);
      setTotalProducts(props.data.valor_total);
      setSelectedConsumer({
        nome: props.data.nome_cliente,
        telefone: props.data.telefone_cliente,
      });
      setAddress(props.data.endereco);
    }
  }, [props.data]);

  function handleGetPaymentsMethods() {
    getPaymentMethodByMenu()
      .then((res) => {
        setPaymentMethods(res.data.data.rows);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleOpenPaymentMethodModal() {
    handleGetPaymentsMethods();
    setOpenPaymentMethodModal(true);
  }
  useEffect(() => {
    if (delivery) {
      handleOpenPaymentMethodModal()
    }
  }, [delivery]);

  function handleSelectProduct(item, index) {
    setActivateProduct(index);
    setSelectedProduct(item);
    setSubTotal(item.preco_venda_vista);
    setAmountProduct(1);
    setObservation("");
    setSelectedSize(null);
    setAddiotionals([]);
    handleOpenAdittionalModal(item.cod_produto);
  }

  function handleOpenAdittionalModal(id) {
    setIdProduct(id);
    setOpenAdditionalModal(true);
  }

  return (
    <main className="order-create">
      <section className="section-one">
        <p className="title">Criar pedido</p>
        <button onClick={() => props.backView()} className="save">
          Voltar
        </button>
      </section>
      <section className="section-two">
        <div className="aside-left">
          <select
            name="table"
            id=""
            className={`table-select ${error.includes("table-error") ? "error" : ""
              }`}
            onChange={(e) => [setCodTable(e.target.value), setError("")]}
          >
            <option value="">Selecione...</option>
            {table?.map((item, index) => (
              <option key={index} value={item.cod_mesa}>
                {item.descricao}
              </option>
            ))}
          </select>
          <div
            className={`chosen-products ${error.includes("product-error") ? "error" : ""
              }`}
          >
            <p className="section-title">Produtos escolhidos</p>
            <div className="chosen-content">
              <div className="chosen-cart">
                <div className="products">
                  {cart.map((item, index) => (
                    <div className="chosen-product" key={index}>
                      <p className="product-name">
                        <i
                          className="fa-solid fa-xmark"
                          onClick={() =>
                            handleDeleteItemCart(index, item.cod_ipedidomesa)
                          }
                        ></i>
                        {item.quantidade}x {item.descricao}
                      </p>
                      <p className="product-price">
                        R$ {item.valor_unitario?.toFixed(2)}
                      </p>
                    </div>
                  ))}
                </div>
                <div className="box-total">
                  <p>Total: </p>
                  <p>R$ {totalProducts}</p>
                </div>
              </div>
              {selectedProduct ? (
                <>
                  <div className="selected-product">
                    <img
                      crossOrigin="anonymous"
                      src={
                        process.env.REACT_APP_API_URL +
                        `/${selectedProduct.imagem}`
                      }
                      alt=""
                    />
                    <div className="selected-text">
                      <p className="product-name">
                        {amountProduct}x {selectedProduct.descricao} {selectedSize ? `(${selectedSize.descricao})` : ''}
                      </p>
                      <p className="product-price">
                        R$ {subTotal.toFixed(2)}
                      </p>
                      <button
                        className="back"
                        style={{
                          height: 20,
                          fontSize: 11,
                        }}
                        onClick={() => setOpenModalObservation(true)}
                      >
                        Add Observação
                      </button>
                    </div>
                  </div>
                  {/* Ações */}
                  <div className="actions">
                    <p className="sub-total">
                      <span>Sub Total:</span>{" "}
                      <span>R$ {subTotal.toFixed(2)}</span>
                    </p>
                    <div className="box-buttons">
                      <div className="add-sub-item">
                        <i
                          onClick={() =>
                            amountProduct === 1 ? "" : handleSubCount()
                          }
                          className="fa fa-minus"
                        ></i>
                        <span>{amountProduct}</span>

                        <i
                          onClick={() => handleAddCount()}
                          className="fa fa-plus"
                        ></i>
                      </div>
                      <button
                        className="add-item save"
                        onClick={() => [handleAddItem(), setError("")]}
                      >
                        Adicionar item
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div
          className={`aside-right ${error.includes("product-error") ? "error" : ""
            }`}
        >
          <p className="section-title">Seções</p>
          <div className="section-content">
            {loading ? (
              <Load />
            ) : (
              <div className="section-categories">
                {data?.map((item, index) => (
                  <div
                    className={`section-item ${activeCategory === index ? `active-category` : ``
                      }`}
                    key={index}
                    onClick={() => [
                      setProducts(item.tb_produtos),
                      setActiveCategory(index),
                      setActivateProduct(null),
                    ]}
                  >
                    <p>
                      {item.descricao?.split("", 13)}
                      {item.descricao?.length >= 13 ? "..." : ""}
                    </p>
                    <img
                      crossOrigin="anonymous"
                      src={process.env.REACT_APP_API_URL + `/${item.imagem}`}
                      alt=""
                    />
                  </div>
                ))}
              </div>
            )}
            <div className="section-products">
              {products?.map((item, index) => (
                <div
                  className={`section-product ${activeProduct === index ? `active-product` : ``
                    }`}
                  key={index}
                  onClick={() => handleSelectProduct(item, index)}
                >
                  <div className="product-text">
                    <p className="product-name">{item.descricao}</p>
                    <p className="product-price">
                      R$ {item.preco_venda_vista.toFixed(2)}
                    </p>
                  </div>
                  <img
                    crossOrigin="anonymous"
                    src={process.env.REACT_APP_API_URL + `/${item.imagem}`}
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="section-three">
        <div className="content-section-three">
          <label htmlFor="">
            Número de Pessoas:{" "}
            <input
              type="number"
              name="person"
              value={persons}
              className={`${error.includes("persons-error") ? "error" : ""}`}
              onChange={(e) => [setPersons(e.target.value), setError("")]}
            />
          </label>
          <label htmlFor="">
            Serviços %
            <input
              type="number"
              name="percentage"
              value={percentage}
              min={0}
              max={100}
              onChange={(e) => setPercentage(e.target.value)}
            />
          </label>
          <label
            htmlFor=""
            className="d-flex form-check form-switch"
            style={{ alignItems: "center" }}
          >
            Delivery:
            <input
              className="form-check-input"
              type="checkbox"
              id="flexSwitchCheckDefault"
              style={{ width: 50, height: 20, cursor: "pointer" }}
              value={delivery}
              onChange={() => setDelivery(!delivery)}
            />
          </label>
        </div>
      </section>
      <section className="section-four">
        <div className="content-section-four">
          <label className="form-control" htmlFor="" style={{ width: "100%" }}>
            Cliente:{" "}
            <button
              className="form-select"
              style={{ height: 40, textAlign: "start" }}
              onClick={() => setOpenConsumerModal(true)}
            >
              {selectedConsumer.nome}
            </button>
          </label>
          <label
            className="form-control"
            htmlFor=""
            style={{ marginTop: 15, width: "100%" }}
          >
            Telefone do Cliente:
            <input
              className="form-control"
              disabled
              value={selectedConsumer.telefone}
              style={{ height: 40, width: "100%", textAlign: "start" }}
            />
          </label>
          <label htmlFor="" className="reference form-control">
            Endereço:{" "}
            <textarea
              name=""
              id=""
              rows="5"
              maxLength={200}
              placeholder="Ex: Próximo ao bar do zé..."
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            ></textarea>
            {address.length}/200
          </label>
          <label htmlFor="" className="form-control">
            <p className="sub-title">Valores a se pagar:</p>
            <div className="value-information p-2">
              <p>Produtos: R$ {parseFloat(totalProducts)?.toFixed(2)}</p>
              <p>
                Serviço: R${" "}
                {delivery ? parseFloat(serviceValue).toFixed(2) : "0.00"}
              </p>
            </div>
            <p className="value-total p-2">
              <span>Total:</span>{" "}
              <span>
                R$
                {delivery
                  ? (
                    parseFloat(totalProducts) + parseFloat(serviceValue)
                  ).toFixed(2)
                  : parseFloat(totalProducts).toFixed(2)}
              </span>
            </p>{" "}
            <div>
              <PaymentMethodModal
                open={openPaymentMethodModal}
                data={paymentMethods}
                setDataPaymentMthod={(data) => [
                  setSelectedPaymentMethod(data),
                  setOpenPaymentMethodModal(false),
                ]}
                setFalse={() => setOpenPaymentMethodModal(false)}
                setIndex={(index) => setIndexPayment(index)}
              />
            </div>
          </label>
        </div>
      </section>
      <div className="payment-methods">
        <label
          className="form-control"
          htmlFor=""
          style={{ width: "100%", marginTop: "20px" }}
        >
          <span className="sub-title">Forma de Pagamento: </span>
          <button
            className="form-select payment-method-description"
            style={{ height: 40, textAlign: "start" }}
            onClick={() => handleOpenPaymentMethodModal()} // Agora sempre abrirá a modal
          >
            {selectedPaymentMethod.descricao}
          </button>
        </label>
      </div>
      <div className="finalize-order">
        <button className="save" onClick={() => handleCreateOrder()}>
          {!loading && (
            <span className="indicator-label">Criar Pedido</span>
          )}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
      <ObservationModal
        open={openModalObservation}
        setFalseModal={() => setOpenModalObservation(false)}
        setObservationText={(text) => setObservation(text)}
      />
      <ConsumerModal
        open={openConsumerModal}
        setFalse={() => setOpenConsumerModal(false)}
        setDataConsumer={(data) => setSelectedConsumer(data)}
        setAddress={(address) => setAddress(address)}
      />
      <SelectAdditionalModal
        open={openAdditionalModal}
        setFalse={() => setOpenAdditionalModal(false)}
        id={idProduct}
        setAddiotionals={(addiotionals) => setAddiotionals(addiotionals)}
        setSelectedSize={(size) => setSelectedSize(size)} // Recebe o tamanho selecionado
        setselectedSabores={(sabor) => setselectedSabores(sabor)}
        amountProduct={amountProduct}
        setSubTotal={(value) => setSubTotal(value)} // Atualiza o subtotal
      />
      <PaymentMethodModal
        open={openPaymentMethodModal}
        data={paymentMethods}
        setDataPaymentMthod={(data) => [
          setSelectedPaymentMethod(data),
          setOpenPaymentMethodModal(false),
        ]}
        setFalse={() => setOpenPaymentMethodModal(false)}
        setIndex={(index) => setIndexPayment(index)}
      />
    </main>
  );
};

export default CriarPedidos;
