import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { handleCreateTamanho } from "../../../services/TamanhoService";

function CadSecaoTamanho(props) {
    const [tamanho, setTamanho] = useState({
        descricao: "",
        cod_org: localStorage.getItem("cod_org"),
        cod_secao: props.secao?.cod_secao
    });

    const token = localStorage.getItem("token");

    // Carregar dados de `props.data` no estado apenas quando `props.data` mudar
    useEffect(() => {
        if (props.data) {
            setTamanho(props.data);
        }
    }, [props.data]);

    // Função para atualizar o estado ao digitar no input
    function onInputChange(event) {
        const { name, value } = event.target; // Pegar o 'name' e o 'value' do input
        setTamanho((prevState) => ({
            ...prevState,
            [name]: value,
            cod_org: localStorage.getItem("cod_org"),
            cod_secao: props.secao
        }));
    }

    // Função para enviar o formulário
    function onSubmit(event) {
        event.preventDefault();

        if (!tamanho.descricao) {
            return toast.warning("Preencha todos os campos obrigatórios!");
        }

        handleCreateTamanho(tamanho, token)
            .then((result) => {
                setTamanho({
                    descricao: result?.descricao,
                    cod_org: localStorage.getItem("cod_org"),
                    cod_secao: props.secao
                });

                if (props.onSubmit) props.onSubmit(result);
                toast.success("Tamanho salvo com sucesso!");
                props.onClose();
            })
            .catch((err) => {
                console.error(err.response ? err.response.data : err.message);
                toast.error("Erro ao salvar o tamanho!");
            });
    }

    return (
        <div
            className={`modal fade ${props.openModal ? 'show' : ''}`}
            style={{ display: props.openModal ? 'block' : 'none' }}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="modalTitleNotify"
            aria-hidden={!props.openModal}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <p className="modal-title" id="modalTitleNotify">
                            {props.data?.cod_secao ? "Alterar " : "Novo "} Tamanho
                        </p>
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="close"
                            onClick={props.onClose}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <input
                                        className="form-control"
                                        id="descricao"
                                        name="descricao" // Nome do campo
                                        type="text"
                                        placeholder="Informe a descrição"
                                        value={tamanho.descricao || ""} // Estado controlado
                                        onChange={onInputChange} // Atualiza o estado ao digitar
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={props.onClose}
                        >
                            Cancelar
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={onSubmit}
                        >
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CadSecaoTamanho;
