import React, { useEffect, useState } from "react";
import "./SelectAdditionalModal.scss";
import { Box, Modal } from "@mui/material";
import { getAdditionalsByIdProduct, getSaboressByIdProduct } from "../../../helpers/Api";
import { Load } from "../../../widgets/spinner/Spinner";
import { getTamanhoByCod } from "../../../../services/TamanhoService";

const SelectAdditionalModal = ({ ...props }) => {
  const [additionals, setAdditionals] = useState([]);
  const [data, setData] = useState([]);
  const [hasIndexies, setHasIndexies] = useState([]);
  const [limitSection, setLimitSection] = useState([]);
  const [tamanhos, setTamanhos] = useState([]);
  const [sabores, setSabores] = useState([]);
  const [selectedTamanho, setSelectedTamanho] = useState(null);
  const [selectedSabores, setSelectedSabores] = useState([]);
  const [saborString, setSaborString] = useState("");
  const [price, setPrice] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleGetAdditionals();
    handleGetSabores();
    handleGetTamanhos();
  }, [props.id]);

  function handleGetAdditionals() {
    setHasIndexies([])
    setData([])
    setLimitSection([])
    setLoading(true);
    getAdditionalsByIdProduct(props.id)
      .then((res) => {
        if (res.data.product) {
          const newData = res.data.product.tb_secaos?.map((item) => {
            return {
              ...item,
              quantidade: 0,
              tb_produtos: item.tb_produtos?.map((subItem) => ({
                ...subItem,
                quantidade: 0,
              })),
            };
          });
          setData(newData);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleAddAdditionals(indexSection, indexProduct, product) {
    const productPrice = selectedTamanho ? selectedTamanho.valor : data[indexSection].tb_produtos[indexProduct].preco_venda_vista;
    setPrice((prevPrice) => prevPrice + productPrice);

    setData((prevData) => {
      const newData = [...prevData];
      const section = { ...newData[indexSection] };
      const products = [...section.tb_produtos];
      const productItem = { ...products[indexProduct] };
      productItem.quantidade = (productItem.quantidade || 0) + 1;
      products[indexProduct] = productItem;
      section.tb_produtos = products;
      section.quantidade = (section.quantidade || 0) + 1;
      newData[indexSection] = section;
      return newData;
    });

    setHasIndexies((prevHasIndexies) => [...prevHasIndexies, indexProduct]);

    setAdditionals((prevAdditionals) => [
      ...prevAdditionals,
      {
        amount: (additionals.find((ad) => ad.cod_produto === product.cod_produto)?.amount || 0) + 1,
        name: product.descricao,
        price: productPrice,
        cod_produto: product.cod_produto,
        cod_secao: product.cod_fk_secao,
      },
    ]);

    if (data[indexSection].quantidade + 1 === data[indexSection].web_quantidade) {
      setLimitSection((prevLimitSection) => [...prevLimitSection, indexSection]);
    }
  }

  function handleSubAdditionals(indexSection, indexProduct, product) {
    const productPrice = selectedTamanho ? selectedTamanho.valor : data[indexSection].tb_produtos[indexProduct].preco_venda_vista;
    setPrice((prevPrice) => prevPrice - productPrice);

    setData((prevData) => {
      const newData = [...prevData];
      const section = { ...newData[indexSection] };
      const products = [...section.tb_produtos];
      const productItem = { ...products[indexProduct] };
      productItem.quantidade = (productItem.quantidade || 1) - 1;
      products[indexProduct] = productItem;
      section.tb_produtos = products;
      section.quantidade = (section.quantidade || 1) - 1;
      newData[indexSection] = section;
      return newData;
    });

    setHasIndexies((prevHasIndexies) =>
      prevHasIndexies.filter((item) => item !== indexProduct)
    );

    setAdditionals((prevAdditionals) =>
      prevAdditionals.filter((item) => item.cod_produto !== product.cod_produto)
    );

    setLimitSection((prevLimitSection) =>
      prevLimitSection.filter((item) => item !== indexSection)
    );
  }

  function handleAdditional(indexSection, indexProduct, product) {
    const productPrice = selectedTamanho ? selectedTamanho.valor : data[indexSection].tb_produtos[indexProduct].preco_venda_vista;

    setPrice((prevPrice) => {
      const existingAdditional = additionals.find(
        (ad) => ad.cod_secao === product.cod_fk_secao
      );
      if (existingAdditional) {
        return prevPrice - existingAdditional.price + productPrice;
      } else {
        return prevPrice + productPrice;
      }
    });

    setAdditionals((prevAdditionals) => {
      const filtered = prevAdditionals.filter(
        (item) => item.cod_secao !== product.cod_fk_secao
      );
      filtered.push({
        amount: 1,
        name: product.descricao,
        price: productPrice,
        cod_produto: product.cod_produto,
        cod_secao: product.cod_fk_secao,
      });
      return filtered;
    });
  }

  function handleGetTamanhos() {
    setLoading(true);
    getTamanhoByCod(props.id)
      .then((res) => {
        setTamanhos(res);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleGetSabores() {
    setLoading(true);
    getSaboressByIdProduct(props.id)
      .then((res) => {
        console.log(res.data.product.tb_secaos)
        setSabores(res.data.product.tb_secaos);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleClearFields() {
    props.setFalse();
    setAdditionals([]);
    setSabores([])
    setSelectedSabores([])
    setHasIndexies([]);
    handleGetAdditionals();
    setPrice(0); // Reset the pric
  }

  function handleTamanhoChange(event) {
    const tamanhoSelecionado = tamanhos.find(
      (tamanho) => tamanho.descricao === event.target.value
    );
    setSelectedTamanho(tamanhoSelecionado);

    if (tamanhoSelecionado) {
      const newPrice = tamanhoSelecionado.valor;
      setPrice(newPrice);
    }
  }

  function handleSaborChange(event) {
    const { value, checked } = event.target;

    setSelectedSabores((prevSelectedSabores) => {
      let updatedSabores;
      if (checked) {
        updatedSabores = [...prevSelectedSabores, value];
      } else {
        updatedSabores = prevSelectedSabores.filter((sabor) => sabor !== value);
      }

      const saborPricesPromises = updatedSabores.map((sabor) => {
        const selectedSabor = sabores.find((secao) =>
          secao.tb_produtos.some((produto) => produto.descricao === sabor)
        );

        if (selectedSabor) {
          return Promise.all(selectedSabor.tb_produtos.map(produto => getTamanhoByCod(produto.cod_produto)))
            .then((res) => {
              const maxPrice = Math.max(...res.flat().map((tamanho) => tamanho.valor));
              return maxPrice;
            })
            .catch((error) => {
              console.error("Erro ao buscar tamanhos para o sabor: ", error);
              return -Infinity;
            });
        }
        return Promise.resolve(0);
      });

      Promise.all(saborPricesPromises).then((prices) => {
        const maxSaborPrice = Math.max(...prices);
        setPrice(maxSaborPrice);
      });

      return updatedSabores;
    });
  }

  useEffect(() => {
    const saboresString = selectedSabores.join(", ");
    setSaborString(saboresString);
  }, [selectedSabores]);
  // Construir a string com os sabores selecionados

  function handleSave() {
    if (!selectedTamanho && tamanhos.length > 0) {
      alert("Por favor, selecione um tamanho.");
      return;
    }
    props.setSubTotal(price)
    props.setAddiotionals(additionals);
    props.setSelectedSize(selectedTamanho)
    props.setselectedSabores(saborString)
    handleClearFields();

  }
  console.log(additionals)
  return (
    <Modal open={props.open} onClose={handleClearFields}>
      <Box>
        <main className="select-additional-modal">
          <section className="first-section">
            <p className="title"></p>
            <i
              className="fa-solid fa-xmark"
              role="button"
              onClick={() => handleClearFields()}
            ></i>
          </section>
          <section
            style={{
              marginTop: "20px",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#555",
                marginBottom: "10px",
              }}
            >
              Selecione um tamanho
            </p>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
                gap: "15px",
                marginTop: "10px",
              }}
            >
              {tamanhos.map((tamanho, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "10px",
                    border: "1px solid #e0e0e0",
                    borderRadius: "5px",
                    backgroundColor: "#f9f9f9",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f0")}
                  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#f9f9f9")}
                >
                  <input
                    type="radio"
                    name="tamanho"
                    id={`tamanho-${tamanho.cod_tamanho}`}
                    onChange={handleTamanhoChange}
                    value={tamanho.descricao}
                    style={{ marginRight: "10px" }}
                  />
                  <label htmlFor={`tamanho-${tamanho.cod_tamanho}`}>
                    {tamanho.descricao}
                  </label>
                  <span
                    style={{
                      fontWeight: "600",
                      color: "#333",
                      fontSize: "14px",
                    }}
                  >
                    R${parseFloat(tamanho.valor).toFixed(2)}
                  </span>
                </div>
              ))}
            </div>
          </section>
          {Array.isArray(sabores) && sabores.length && sabores.map((secao) => (
            <section
              key={secao.cod_secao}
              style={{
                marginTop: "20px",
              }}
            >
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#555",
                  marginBottom: "10px",
                }}
              >
                Selecione um Sabor
              </p>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
                  gap: "15px",
                  marginTop: "10px",
                }}
              >
                {secao.tb_produtos.map((produto, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "10px",
                      border: "1px solid #e0e0e0",
                      borderRadius: "5px",
                      backgroundColor: "#f9f9f9",
                      transition: "background-color 0.3s ease",
                    }}
                    onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f0")}
                    onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#f9f9f9")}
                  >
                    <label>
                      <input
                        type="checkbox"
                        value={produto.descricao}
                        onChange={handleSaborChange}
                      />
                      {produto.descricao}
                    </label>
                  </div>
                ))}
              </div>
            </section>
          ))}



          <p
            className="sub-title"
            style={{
              textAlign: "center",
              borderBottom: "2px solid #f2f2f2",
            }}
          >
            Adicionais
          </p>
          <section className="second-section">
            <div className="additionals">
              {loading ? (
                <Load />
              ) : data?.length === 0 ? (
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: 500,
                    marginTop: 30,
                  }}
                >
                  Esse produto não tem adicionais
                </p>
              ) : (
                data?.map((item, index) => (
                  <div key={index}>
                    {item.acompanhamento === "S" && (
                      <section className="additional">
                        <p className="sub-title--additional">
                          {item.descricao} (Escolha{" "}
                          {item.web_quantidade > 1
                            ? `até ${item.web_quantidade} itens`
                            : "um item"}
                          )
                        </p>
                        {item.tb_produtos.map((subItem, subIndex) => (
                          <div className="additional-item" key={subIndex}>
                            <div className="item-information">
                              <span className="item-information-title">
                                {subItem.descricao}
                              </span>
                              <br />
                              {selectedTamanho ? (
                                <span className="item-information-price">
                                  R${parseFloat(selectedTamanho.valor).toFixed(2)}
                                </span>
                              ) : (
                                <span className="item-information-price">
                                  R${parseFloat(subItem.preco_venda_vista).toFixed(2)}
                                </span>
                              )}
                            </div>
                            <div className="item-action">
                              {item.web_quantidade > 1 ? (
                                <div className="amount">
                                  <button
                                    onClick={() =>
                                      handleSubAdditionals(
                                        index,
                                        subIndex,
                                        subItem
                                      )
                                    }
                                    disabled={
                                      !subItem.quantidade ||
                                      subItem.quantidade === 0
                                    }
                                  >
                                    -
                                  </button>
                                  <span>{subItem.quantidade || 0}</span>
                                  <button
                                    onClick={() =>
                                      handleAddAdditionals(
                                        index,
                                        subIndex,
                                        subItem
                                      )
                                    }
                                    disabled={
                                      limitSection.includes(index) ||
                                      hasIndexies.includes(subIndex)
                                    }
                                  >
                                    +
                                  </button>
                                </div>
                              ) : (
                                <input
                                  type="radio"
                                  name={`additional--one-${item.cod_secao}`}
                                  onChange={() =>
                                    handleAdditional(index, subIndex, subItem)
                                  }
                                />
                              )}
                            </div>
                          </div>
                        ))}
                      </section>
                    )}
                  </div>
                ))
              )}
            </div>
          </section>
          <button className="save" onClick={handleSave}>
            Salvar
          </button>
        </main>
      </Box>
    </Modal>
  );
};

export default SelectAdditionalModal;

