import React, { useRef, useState, useEffect } from "react";
import { salvarProduto } from "../../../services/ProdutoService";
import { getSecoes, getTodasSecoes } from "../../../services/SecaoService";
import { getTodasTributacoes } from "../../../services/TributacaoService";
import { getEstoqueProduto } from "../../../services/EstoqueService";
import SwitchInput from "./SwitchInput";
import { formatarMoeda } from "../../../utils/utils";
import { toast } from "react-toastify";
import SectionAdditionalModal from "../../../components/partials/modals/section-additional-modal/SectionAdditionalModal";
import { getTamanhos } from "../../../services/TamanhoService"; // Importe a função do seu service
import { getTamanhoByCod } from "../../../services/TamanhoService";


import "./CadProdutoModal.scss";

/**
 * props:
 * - data
 * - onSubmit
 */

function CadProdutoModal(props) {
  const [secao, setSecao] = useState(["CARREGANDO"]);
  const [tributacao, setTributacao] = useState(["CARREGANDO"]);
  const [erro, setError] = useState("");
  const [estoque, setEstoque] = useState({});
  const [produto, setProduto] = useState({
    situacao: "Ativo",
    preco_venda_vista: 0.0,
  });
  const [tamanhos, setTamanhos] = useState([]);
  const btnClose = useRef("");
  const btnSalvar = useRef("");
  const token = localStorage.getItem("token");
  const [file, setFile] = useState("");
  const [chosenSection, setChosenSection] = useState({});
  const [openAdditionalModal, setOpenAdditionalModal] = useState(false);
  const [dataAdditional, setDataAddiotional] = useState([]);
  const [ids, setIds] = useState([]);
  const [deletedIndexies, setDeletedIndexies] = useState([]);
  const [isAdditional, setIsAdditional] = useState(false);
  const [solicitaSabores, setSolicitaSabores] = useState(null);

  const cod_org = localStorage.getItem("cod_org")
  useEffect(() => {
    setIds([]);
    setDeletedIndexies([]);
    setProduto(props.data);
    handleSections();

    if (props.data.preco_venda_vista != undefined) {
      document.getElementById("preco_venda_vista").value =
        props?.data?.preco_venda_vista?.toFixed(2);
    } else {
      document.getElementById("preco_venda_vista").value = 0;
    }
    document.getElementById("descricao").value = props?.data?.descricao;

    if (props.data?.tb_secaos?.length == 1) {
      if (props.data.tb_secaos[0].acompanhamento === "S") {
        setIsAdditional(true);
      }
    } else {
      setIsAdditional(false);
    }
  }, [props.data]);

  function handleSections() {
    let array = [];
    props?.data?.tb_secaos?.forEach((item) => {
      return item.acompanhamento === "S" ? array.push(item) : "";
    });
    setDataAddiotional(array);

    let arrayIds = [];
    array.forEach((item) => {
      if (props.data.cod_fk_secao != item.cod_secao) {
        arrayIds.push(item.cod_secao);
      }
    });
    setIds(arrayIds);
  }
  useEffect(() => {
    getSecoes("", 1, null, "all", "all")
      .then((res) => {
        setSecao(res.rows);
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
      });
  }, []);

  useEffect(() => {
    getTodasTributacoes(token)
      .then((result) => {
        setTributacao(result.rows);
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
      });
  }, []);

  function normalizeValue(value) {
    if (value === null || value === undefined) {
      return "";
    }
    return value;
  }

  function handleAddTamanho() {
    setTamanhos([...tamanhos, { descricao: "", valor: 0 }]);
  }

  function handleRemoveTamanho(index) {
    const newTamanhos = tamanhos.filter((_, i) => i !== index);
    setTamanhos(newTamanhos);
  }

  function handleTamanhoChange(index, event) {
    const { name, value } = event.target;
    const newTamanhos = tamanhos.map((tamanho, i) =>
      i === index ? { ...tamanho, [name]: value } : tamanho
    );
    setTamanhos(newTamanhos);
  }

  function onSubmit(event) {
    event.preventDefault();
    produto.cod_org = localStorage.getItem("cod_org");

    if (file) {
      if (
        file.type !== "image/png" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/jpg" &&
        file.type !== "image/webp"
      ) {
        return toast.warning("Tipo de arquivo não suportado !!");
      }
      if (file.size > 100000) {
        return toast.warning("A imagem não pode exceder 100kb !!");
      }
    }

    const tamanhosSemValor = tamanhos.filter(
      (tamanho) => !tamanho.valor || tamanho.valor <= 0
    );
    if (tamanhosSemValor.length > 0) {
      return toast.warning("Por favor, insira o valor para todos os tamanhos.");
    }

    if (produto.descricao.length < 3) {
      return toast.warning("Insira um nome para o seu produto !!");
    }
    if (!produto.cod_fk_secao) {
      return toast.warning("Selecione a seção do seu produto !!");
    }
    if (!produto.cod_fk_tributacao) {
      return toast.warning("Selecione a tributação do seu produto !!");
    }


    const formData = new FormData();
    formData.append("imagem", file);
    formData.append("descricao", normalizeValue(produto.descricao));
    formData.append("cod_fk_secao", normalizeValue(produto.cod_fk_secao));
    formData.append("listar_secao", normalizeValue(secao.listar_secao));
    formData.append("cod_org", normalizeValue(produto.cod_org));
    formData.append("preco_venda_vista", normalizeValue(produto.preco_venda_vista) || 0);
    formData.append("situacao", normalizeValue(produto.situacao));
    formData.append("old_path_image", normalizeValue(produto.imagem));
    formData.append("cod_fk_tributacao", normalizeValue(produto.cod_fk_tributacao));
    formData.append("tipo", normalizeValue(produto.tipo) || "P");
    formData.append("unidade", normalizeValue(produto.unidade) || "UN");
    formData.append("additionals", JSON.stringify(normalizeValue(ids)));
    formData.append("deleted_ids", JSON.stringify(normalizeValue(deletedIndexies)));
    formData.append("old_section", normalizeValue(props.data.cod_fk_secao));

    if (produto.cod_barras !== undefined) {
      formData.append("cod_barras", normalizeValue(produto.cod_barras));
    }
    if (produto.cod_fabricante !== undefined) {
      formData.append("cod_fabricante", normalizeValue(produto.cod_fabricante));
    }
    if (produto.marca !== undefined) {
      formData.append("marca", normalizeValue(produto.marca));
    }

    formData.append("cod_balanca", normalizeValue(produto.cod_balanca) || 0);
    formData.append("preco_custo", normalizeValue(produto.preco_custo) || 0);
    formData.append("preco_custo_liquido", normalizeValue(produto.preco_custo_liquido) || 0);
    formData.append("taxa_remarcacao", normalizeValue(produto.taxa_remarcacao) || 0);
    formData.append("est_minimo", normalizeValue(produto.est_minimo) || 0);
    formData.append("embalagem", normalizeValue(produto.embalagem) || 0);
    formData.append("preco_venda_atacado", normalizeValue(produto.preco_venda_atacado) || 0);
    formData.append("web_adicional", normalizeValue(chosenSection === "S"));
    if (solicitaSabores === "S") {
      formData.append("solicita_sabores", solicitaSabores);
    }

    if (produto.ncm !== undefined) {
      formData.append("ncm", normalizeValue(produto.ncm));
    }
    if (produto.locacao !== undefined) {
      formData.append("locacao", normalizeValue(produto.locacao));
    }
    if (produto.aplicacao !== undefined) {
      formData.append("aplicacao", normalizeValue(produto.aplicacao));
    }
    if (produto.anp !== undefined) {
      formData.append("cod_anp", normalizeValue(produto.anp));
    }
    if (produto.cest !== undefined) {
      formData.append("cest", normalizeValue(produto.cest));
    }
    if (produto.cod_barras_emb !== undefined) {
      formData.append("cod_barras_emb", normalizeValue(produto.cod_barras_emb));
    }
    formData.append("est_promo_emp", normalizeValue(produto.est_promo_emp) || 0);

    // Adiciona tamanhos ao formData
    formData.append("tamanhos", JSON.stringify(normalizeValue(tamanhos)));

    salvarProduto(produto.cod_produto, formData, token)
      .then((result) => {
        // Reseta os campos do produto
        Object.keys(produto).forEach(key => {
          produto[key] = "";
        });

        setTamanhos([]); // Limpa os tamanhos cadastrados
        document.getElementById("formFile").value = "";
        document.getElementById("descricao").value = "";
        document.getElementById("preco_venda_vista").value = "";

        btnClose.current.click();
        if (props.onSubmit) props.onSubmit(result);
        if (produto.cod_produto) {
          toast.success("Produto alterado com sucesso !!");
        } else {
          toast.success("Produto criado com sucesso !!");
        }
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
        setError(err.response ? err.response.data : err.message);
      });
  }

  function onInputChange(event) {
    if (
      event.target.id === "preco_custo" ||
      event.target.id === "preco_custo_liquido" ||
      event.target.id === "taxa_remarcacao" ||
      event.target.id === "preco_venda_vista" ||
      event.target.id === "est_minimo" ||
      event.target.id === "embalagem" ||
      event.target.id === "preco_venda_atacado"
    ) {
      setError("");

      let valor = formatarMoeda(event);

      valor = valor.replace(".", "");
      valor = valor.replace(",", ".");

      if (valor > 0) {
        setProduto((prevState) => ({ ...prevState, [event.target.id]: valor }));
      }
    } else {
      setProduto((prevState) => ({
        ...prevState,
        [event.target.id]: event.target.value,
      }));

      if (event.target.id === "cod_fk_secao") {
        setProduto((prevState) => ({
          ...prevState,
          [event.target.id]: event.target.value,
        }));

        getTamanhos(cod_org, event.target.value)
          .then((res) => {
            const tamanhosSecao = res.data;
            setTamanhos(
              tamanhosSecao.map((tamanho) => ({
                ...tamanho,
                valor: 0,
              }))
            );
          })
          .catch((err) => {
            console.error(err.response ? err.response.data : err.message);
            setTamanhos([]);
          });

      }
    }
  }

  useEffect(() => {
    if (produto.cod_produto) {
      getEstoqueProduto(produto.cod_produto, token)
        .then((result) => {
          setEstoque(result);
        })
        .catch((err) => {
          console.error(err.response ? err.response.data : err.message);
        });
      getTamanhoByCod(produto.cod_produto)
        .then((result) => {
          setTamanhos(result);
        })
        .catch((err) => {
          console.error(err.response ? err.response.data : err.message);
        });
    }
  }, [produto]);

  function handleTamanhoChange(index, event) {
    const { name, value } = event.target;

    // Atualiza o valor do tamanho com base no índice correto
    const newTamanhos = tamanhos.map((tamanho, i) =>
      i === index ? { ...tamanho, [name]: parseFloat(value) || 0 } : tamanho
    );

    // Atualiza o estado
    setTamanhos(newTamanhos);
  }


  return (
    <div
      className="modal fade bd-example-modal-lg"
      id="modalProduto"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalTitleNotify"
      aria-hidden="true"
    >
      <script
        type="text/javascript"
        src="https://rawgit.com/RobinHerbots/jquery.inputmask/3.x/dist/jquery.inputmask.bundle.js"
      ></script>

      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <p className="modal-title" id="modalTitleNotify">
              {props.data.cod_mesa ? "Alterar " : "Novo "}Produto
            </p>
            <button
              ref={btnClose}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <div className="row">
                <div className="col-md-3 text-center">
                  <label htmlFor="formFile" className="form-label">
                    Imagem Produto
                  </label>
                  <img
                    className="img-thumbnail imgpx200"
                    alt="..."
                    crossOrigin="anonymous"
                    src={
                      props?.data?.imagem
                        ? process.env.REACT_APP_API_URL +
                        `/${props.data.imagem}`
                        : "/img/image.png"
                    }
                  />
                  <input
                    className="form-control form-control-sm"
                    type="file"
                    accept="image/*"
                    id="formFile"
                    onChange={(e) => setFile(e.target.files[0])}
                    multiple
                  />
                </div>

                <div className="col-md-9 mb-3">
                  <div className="row">
                    <div className="col-md-7 mb-3">
                      <label
                        htmlFor="descricao"
                        align="Left"
                        className="required"
                      >
                        Nome do Produto
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="descricao"
                        type="text"
                        placeholder="Informe a Descrição"
                        defaultValue={produto.descricao}
                        onChange={onInputChange}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="tipo" align="Left" className="required">
                        Tipo Produto
                      </label>
                      <select
                        className="form-control form-control-sm"
                        value={produto.tipo}
                        name="tipo"
                        id="tipo"
                        onChange={onInputChange}
                      >
                        <option value="P">Produto</option>
                        <option value="S">Serviço</option>
                      </select>
                    </div>
                    <div className="col-md-2 mb-3">
                      <div className="form-group">
                        <label htmlFor="descricao" align="Left">
                          Situação
                        </label>
                        <SwitchInput
                          id="situacao"
                          text={produto.situacao}
                          onChange={onInputChange}
                          isChecked={produto.situacao}
                        />
                      </div>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="solicitaSabores"
                        checked={solicitaSabores}
                        onChange={(e) => setSolicitaSabores("S")}
                      />
                      <label className="form-check-label" htmlFor="solicitaSabores">
                        Solicita Sabores
                      </label>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-3 mb-3">
                      <label htmlFor="secao" align="Left" className="required">
                        Seção
                      </label>
                      <select
                        className="form-control form-control-sm"
                        value={produto.cod_fk_secao}
                        name="cod_fk_secao"
                        id="cod_fk_secao"
                        onChange={onInputChange}
                      >
                        <option value="">Selecione...</option>
                        {secao.map((s) => (
                          <option
                            key={"secao-" + s.cod_secao}
                            value={s.cod_secao}
                          >
                            {s.descricao}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="cod_barras" align="Left">
                        Codigo Barras
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="cod_barras"
                        type="text"
                        defaultValue={produto.cod_barras}
                        onChange={onInputChange}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="cod_fabricante" align="Left">
                        Codigo Fabricante
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="cod_fabricante"
                        type="text"
                        defaultValue={produto.cod_fabricante}
                        onChange={onInputChange}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="cod_balanca" align="Left">
                        Codigo Balança
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="cod_balanca"
                        type="text"
                        defaultValue={produto.cod_balanca}
                        onChange={onInputChange}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-2 mb-3">
                      <label
                        htmlFor="descricao"
                        align="Left"
                        className="required"
                      >
                        Unidade
                      </label>
                      <select
                        className="form-control form-control-sm"
                        value={produto.unidade}
                        name="unidade"
                        id="unidade"
                        onChange={onInputChange}
                      >
                        <option value="UN">UN</option>
                        <option value="KG">KG</option>
                        <option value="MT">MT</option>
                        <option value="PC">PC</option>
                      </select>
                    </div>
                    <div className="col-md-2 mb-3">
                      <label htmlFor="preco_custo" align="Left">
                        Custo
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="preco_custo"
                        type="text"
                        defaultValue={produto.preco_custo}
                        onChange={onInputChange}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="preco_custo_liquido" align="Left">
                        Custo Liquido
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="preco_custo_liquido"
                        type="text"
                        defaultValue={produto.preco_custo_liquido}
                        onChange={onInputChange}
                      />
                    </div>
                    <div className="col-md-2 mb-3">
                      <label htmlFor="taxa_remarcacao" align="Left">
                        % Lucro
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="taxa_remarcacao"
                        type="text"
                        defaultValue={produto.taxa_remarcacao}
                        onChange={onInputChange}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label
                        htmlFor="preco_venda_vista"
                        align="Left"
                        className="required"
                      >
                        Preço de Venda
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="preco_venda_vista"
                        type="text"
                        defaultValue={produto.preco_venda_vista}
                        onChange={onInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button
                    className="nav-link active"
                    id="nav-dados-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-dados"
                    type="button"
                    role="tab"
                    aria-controls="nav-dados"
                    aria-selected="true"
                  >
                    Dados
                  </button>
                  <button
                    className="nav-link"
                    id="nav-estoque-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-estoque"
                    type="button"
                    role="tab"
                    aria-controls="nav-estoque"
                    aria-selected="false"
                  >
                    Estoque
                  </button>
                  <button
                    className="nav-link"
                    id="nav-trib-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-trib"
                    type="button"
                    role="tab"
                    aria-controls="nav-trib"
                    aria-selected="false"
                  >
                    Tributação
                  </button>
                  <button
                    className="nav-link"
                    id="nav-tamanhos-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-tamanhos"
                    type="button"
                    role="tab"
                    aria-controls="nav-tamanhos"
                    aria-selected="false"
                  >
                    Tamanhos
                  </button>
                  {isAdditional ? (
                    ""
                  ) : (
                    <button
                      className="nav-link"
                      id="nav-additional-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-additional"
                      type="button"
                      role="tab"
                      aria-controls="nav-additional"
                      aria-selected="false"
                    >
                      Adicionais
                    </button>
                  )}
                </div>
              </nav>

              <div className="tab-content" id="nav-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="nav-dados"
                  role="tabpanel"
                  aria-labelledby="nav-dados-tab"
                >
                  <br></br>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="marca" align="Left">
                        Marca
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="marca"
                        type="text"
                        defaultValue={produto.marca}
                        onChange={onInputChange}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="locacao" align="Left">
                        Locação
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="locacao"
                        type="text"
                        defaultValue={produto.locacao}
                        onChange={onInputChange}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="est_minimo" align="Left">
                        Estoque Minimo
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="est_minimo"
                        type="text"
                        defaultValue={produto.est_minimo}
                        onChange={onInputChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <label htmlFor="cod_barras_emb" align="Left">
                        Codigo Barras Embalagem
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="cod_barras_emb"
                        type="text"
                        defaultValue={produto.cod_barras_emb}
                        onChange={onInputChange}
                      />
                    </div>

                    <div className="col-md-3 mb-3">
                      <label htmlFor="embalagem" align="Left">
                        Embalagem
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="embalagem"
                        type="text"
                        defaultValue={produto.embalagem}
                        onChange={onInputChange}
                      />
                    </div>

                    <div className="col-md-3 mb-3">
                      <label htmlFor="preco_venda_atacado" align="Left">
                        Preço Embalagem
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="preco_venda_atacado"
                        type="text"
                        defaultValue={produto.preco_venda_atacado}
                        onChange={onInputChange}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="aplicacao" align="Left">
                        Aplicação/Observação
                      </label>
                      <textarea
                        className="form-control form-control-sm"
                        id="aplicacao"
                        rows="3"
                        defaultValue={produto.aplicacao}
                        onChange={onInputChange}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-estoque"
                  role="tabpanel"
                  aria-labelledby="nav-estoque-tab"
                >
                  <br></br>

                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <label htmlFor="est_real" align="Left">
                        Estoque Real
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="est_real_emp"
                        type="number"
                        disabled
                        defaultValue={estoque.est_real_emp}
                        onChange={onInputChange}
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="est_fiscal" align="Left">
                        Estoque Fiscal
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="est_fiscal_emp"
                        type="number"
                        disabled
                        defaultValue={estoque.est_fiscal_emp}
                        onChange={onInputChange}
                      />
                    </div>

                    <div className="col-md-4 mb-3">
                      <label htmlFor="est_promo" align="Left">
                        Estoque Promocional
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="est_promo_emp"
                        type="number"
                        disabled
                        defaultValue={produto.est_promo_emp}
                        onChange={onInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-trib"
                  role="tabpanel"
                  aria-labelledby="nav-trib-tab"
                >
                  <br></br>
                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <label
                        htmlFor="tributacao"
                        align="Left"
                        className="required"
                      >
                        Tributação
                      </label>
                      <select
                        className="form-control form-control-sm"
                        value={produto.cod_fk_tributacao}
                        name="cod_fk_tributacao"
                        id="cod_fk_tributacao"
                        onChange={onInputChange}
                      >
                        <option value="">Selecione...</option>
                        {tributacao.map((t) => (
                          <option
                            key={"tributacao" + t.cod_tributacao}
                            value={t.cod_tributacao}
                          >
                            {t.descricao}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="ncm" align="Left">
                        NCM
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="ncm"
                        type="text"
                        defaultValue={produto.ncm}
                        onChange={onInputChange}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="cest" align="Left">
                        CEST
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="cest"
                        type="text"
                        defaultValue={produto.cest}
                        onChange={onInputChange}
                      />
                    </div>
                    <div className="col-md-2 mb-3">
                      <label htmlFor="anp" align="Left">
                        ANP
                      </label>
                      <input
                        className="form-control form-control-sm"
                        id="anp"
                        type="text"
                        defaultValue={produto.anp}
                        onChange={onInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-tamanhos"
                  role="tabpanel"
                  aria-labelledby="nav-tamanhos-tab"
                >
                  <br />
                  {tamanhos.length > 0 ? (
                    tamanhos.map((tamanho, index) => (
                      <div key={index} className="row">
                        <div className="col-md-6 mb-3">
                          <label htmlFor={`descricao_${index}`} align="Left">
                            Tamanho
                          </label>
                          <input
                            className="form-control form-control-sm"
                            id={`descricao_${index}`}
                            name="descricao"
                            type="text"
                            value={tamanho.descricao}
                            readOnly // Tornamos o campo somente leitura, já que vem da seção
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <label htmlFor={`valor_${index}`} align="Left">
                            Valor
                          </label>
                          <input
                            className="form-control form-control-sm"
                            id="preco_venda_vista"
                            type="NUMBER"
                            name="valor"
                            value={tamanho.valor}
                            onChange={(e) => handleTamanhoChange(index, e)} // Certifique-se de passar o índice
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>Não há tamanhos associados a esta seção.</p>
                  )}
                </div>

                {isAdditional ? (
                  ""
                ) : (
                  <div
                    className="tab-pane fade"
                    id="nav-additional"
                    role="tabpanel"
                    aria-labelledby="nav-additional-tab"
                  >
                    <div
                      className="row"
                      style={{ maxHeight: 300, overflowY: "auto" }}
                    >
                      <SectionAdditionalModal
                        setFalse={() => setOpenAdditionalModal(false)}
                        setData={(data) => [setDataAddiotional(data)]}
                        setIds={(ids) => setIds(ids)}
                        ids={ids}
                        data={dataAdditional}
                        deletedIds={(ids) => setDeletedIndexies(ids)}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {erro ? (
              <div className="alert alert-danger mt-1 col-9 py-1">{erro}</div>
            ) : (
              <React.Fragment></React.Fragment>
            )}
            <button
              ref={btnSalvar}
              type="button"
              className="btn btn-sm btn-primary"
              onClick={onSubmit}
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CadProdutoModal;
